/* styles.css */

@font-face {
    font-family: 'YourFontName';
    src: url('./fonts/CustomFont2.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
}

body {
    font-family: 'YourFontName', sans-serif;
}

* {
    box-sizing: border-box;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #211c45;
    position: sticky;
    width: 100%;
}

.navbar-logo img {
    height: 40px;
}

.navbar-menu {
    display: flex;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.navbar-menu a {
    margin: 0 1rem;
    text-decoration: none;
    color: #f5f5f5;
}

.navbar-buy button {
    background-color: #f31c2c;
    color: white;
    border: none;
    padding: .75rem 2rem;
    cursor: pointer;
    font-family: 'YourFontName', sans-serif;
    border-radius: 0%;
}

.navbar-hamburger {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    z-index: 1000;
    height: 40px;
}

.navbar-hamburger span {
    width: 25px;
    height: 3px;
    background-color: #f5f5f5;
    margin: 2px 0;
}

.landing-section {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.video-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden; /* Ensures no scrollbars appear due to negative margin */
}

.video-item {
    flex: 1 0 33.3333%;
    width: 33.3333%;
    max-width: 33.3334%; /* Slightly over 1/3 to account for potential rounding errors */
    aspect-ratio: 16 / 9;
    line-height: 0;
    font-size: 0;
}

.video-item iframe {
    width: calc(100% + 1px); /* Slight overflow to prevent gaps */
    height: calc(100% + 1px); /* Slight overflow to prevent gaps */
    border: none;
    display: block;
    margin: -0.5px; /* Negative margin to overlap borders */
}

.hero-container {
    position: relative;
    width: 100%;
}

.hero-image {
    width: 100%;
}

.hero-image img {
    width: 100%;
    height: auto;
    display: block;
}

.panel-text {
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 0 5%;
}

.panel-text img {
    width: 100%;
    height: auto;
    display: block;
}

.custom-divider {
    width: 100%;
    display: block;
}

.custom-divider img {
    width: 100%;
    height: auto;
    display: block;
}

.about-section {
    text-align: center;
    padding: 2rem;
    background-color: #211c45;
    width: 100%;
}

.about-section h2 {
    color: #f5f5f5;
    font-size: 5rem;
    margin-bottom: 2rem;
    font-family: 'YourFontName', sans-serif;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.2;
}

.about-image {
    width: 100%;
}

.about-image img {
    width: 70%;
    max-width: 800px;
}

.media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 2rem auto;
}

.tweet-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
  }
  
.tweet-container {
width: 100%;
}

.youtube-container {
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
}

.youtube-container iframe {
    width: 100%;
    height: 100%;
}

.info-flexbox {
    display: flex;
    justify-content: space-around;
    margin-top: 2rem;
    flex-wrap: wrap;
    width: 100%;
}

.info-item, .contract-address {
    flex: 1 1 30%;
    margin: 0 1rem 1rem;
    min-width: 200px;
}

.info-item h3, .contract-address h3 {
    font-family: 'YourFontName', sans-serif;
    color: #f5f5f5;
    margin-bottom: 0.5rem;
}

.info-item p, .contract-address p {
    color: #f31c2c;
    font-size: 1.5rem;
    margin-top: 0.5rem;
}

.contract-address {
    flex-basis: 100%;
    margin-bottom: 2rem;
    position: relative;
}

.contract-address p {
    cursor: pointer;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    word-break: break-all;
    max-width: 100%;
}

@media (max-width: 768px) {
    .navbar {
        flex-wrap: wrap;
    }

    .navbar-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        background-color: #211c45;
        position: static;
        transform: none;
        left: auto;
    }

    .navbar-menu.active {
        display: flex;
    }

    .navbar-menu a {
        padding: 1rem;
    }

    .navbar-hamburger {
        display: flex;
    }

    .navbar-buy {
        display: none;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
    }

    .navbar-buy.active {
        display: flex;
    }

    .video-grid {
        grid-template-columns: 1fr;
    }

    .video-item {
        flex: 1 0 100%;
        width: 100%;
        max-width: 100%;
    }

    .hero-image img {
        width: 100%;
        height: auto;
    }

    .panel-text {
        top: 2%;
        padding: 0 2%;
    }

    .about-section h2 {
        font-size: 3rem;
        width: 100%;
        line-height: 1.4;
    }

    .about-image img {
        width: 90%;
    }

    .media-container {
        max-width: 100%;
    }

    .info-flexbox {
        flex-direction: column;
    }

    .info-item, .contract-address {
        margin: 1rem 0;
        width: 100%;
    }

    .contract-address {
        order: -1;
    }

    .contract-address p {
        width: 100%;
    }

    .tweet-grid {
        grid-template-columns: 1fr;
      }
}